@media (max-width: 576px) {
    .font-bold {
        font-size: 1rem; /* Smaller size for mobile */
    }

    .text-sm {
        font-size: 0.875rem; /* Smaller size for mobile */
    }

    .text-xs {
        font-size: 0.75rem; /* Smaller size for mobile */
    }
}