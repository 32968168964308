.back_about {
  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    ),
    url("../../../public/assets/about.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
