.page-content {
  display: flex;
  justify-content: center;
}

.content1_background {
  background-color: #153eaa;
  /* background-image: url("../../assets/manish_2.png"); */
  background-image: linear-gradient(to top, #001f6f, #133ca539),
    url("../../../public/assets/stock_bg.gif");
  background-position: center;
  background-size: cover;
  filter: opacity(90%);
  background-repeat: no-repeat;
  /* height: 40rem; */
  width: 76rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: 5px 5px 15px 5px #b9c6e6;
}

.content2_background {
  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    ),
    url("../../../public/assets/bgStock1.jpg");
  background-position: center;
  background-size: 45%;
  filter: opacity(10%);
}

.section_1 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3rem;
}

.section_2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #fff;
  font-weight: 600;
  background-color: #3259bd;
  padding: 0.7rem;
  border-radius: 0.5rem;
}

.main_heading {
  font-size: clamp(1.5rem, 4vw, 3rem);
  color: #fff;
  font-weight: 700;
  /* margin-bottom: 1rem; */
  margin-top: 1rem;
}

.sub_heading {
  font-size: clamp(1rem, 3vw, 2rem);
  color: #fff;
  font-weight: 500;
}

.call_to_action {
  background-color: #b3c0e0;
  padding: 0.7rem;
  border-radius: 0.5rem;
  font-weight: 500;
}

.call_to_action_div {
  margin-top: 2rem;
}

/* For desktops: */
/* @media only screen and (min-width: 769px) {
  .content1_background {
  }
  .section_1 {
  }
  .section_2 {
  }
  .heading {
  }
  .main_heading {
  }
  .sub_heading {
  }
  .call_to_action {
  }
  .call_to_action_div {
  }
} */

/* For tablets: */
/* @media only screen and (min-width: 601px) and (max-width: 768px) {
  .content1_background {
    background-size: 50%;
    height: 30rem;
    width: auto;
    margin: 2rem;
  }

  .section_1 {
    margin-left: 1rem;
    width: 70%;
  }

  .section_2 {
    width: 0%;
  }

  .heading {
    font-size: 2rem;
    padding: 0.6rem;
    border-radius: 0.5rem;
  }

  .main_heading {
    font-size: 3.4rem;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
  }

  .sub_heading {
    font-size: 1.2rem;
  }

  .call_to_action {}

  .call_to_action_div {}
} */

/* For mobile phones: */
/* @media only screen and (max-width: 600px) {
  .content1_background {
    background-size: 50%;
    height: 35rem;
    width: auto;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    background-position: bottom;
  }

  .section_1 {
    width: 100%;
    margin-left: 1rem;
    margin-top: 2.5rem;
  }

  .section_2 {
    width: 0%;
  }

  .heading {
    font-size: 1.3rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .main_heading {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .sub_heading {
    font-size: 0.8rem;
  }

  .call_to_action {}

  .call_to_action_div {}
} */

/* @media only screen and (max-width: 375px) {
  .content1_background {
    height: 30rem;
    margin: 1.5rem;

  }

  .section_1 {
    margin-left: 0.4rem;
    margin-top: 2.5rem;
  }

  .section_2 {
    width: 0%;
  }

  .heading {
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .main_heading {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .sub_heading {
    font-size: 0.7rem;
  }

  .call_to_action {}

  .call_to_action_div {}
} */

/* @media only screen and (max-width: 320px) {
  .content1_background {
    height: 30rem;
    margin: 1rem;

  }

  .section_1 {
    width: 100%;
    margin-left: 0.4rem;
    margin-top: 2.5rem;
  }

  .section_2 {
    width: 0%;
  }

  .heading {
    font-size: 1.1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  .main_heading {
    font-size: 1.9rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .sub_heading {
    font-size: 0.7rem;
  }

  .call_to_action {
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
  }

  .call_to_action_div {}
} */
